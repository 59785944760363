@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

#root {
  background-color: white;
  font-family: "Montserrat", sans-serif;
  /* width: 100%; */
  /* overflow: hidden; */
}

.card-flip {
  font-size: 0.7rem;
  border: none;
  border-radius: 25px;
  color: #fff;
  text-transform: uppercase;
  background: #8a8a8a6a;
  position: absolute;
  bottom: 10vh;
  left: 50%;
  transform: translate(-50%, 0);
}

.login-input {
  /* display: inline-block; */
  min-width: 8cm;
  color: black;
  padding: 10px;
  width: 250px;
  box-sizing: border-box;
  background-color: rgba(125, 125, 125, 0.25);
  border: none;
  border-radius: 2px;
  margin: 10px auto;
  transition: background-color 0.5s ease;
}

.content-center {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

#theNavbar {
  position: fixed;
  z-index: 10;
  width: 100%;
  box-shadow: 0 3px 6px rgba(66, 64, 64, 0.16), 0 3px 6px rgba(66, 64, 64, 0.16);
}

#navbarMenu {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: fit-content;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}

#navbarMenu a {
  text-decoration: none;
  color: #113e63;
  font-size: 20px;
  font-weight: bold;
}

#navbarMenu a:hover {
  color: #8f9faa;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 80% !important;
  height: 100% !important;
}

.btn-outline-success.custom-btn {
  border-color: #4ab29d;
  color: #4ab29d;
}
.btn-outline-success.custom-btn:hover {
  background-color: #4ab29d; /* Green */
  color: white;
}

/* .form-control-modified:focus {
  border-color: #4AB29D;
  box-shadow: 0px 1px 1px rgba(74, 178, 157, 0.75) inset, 0px 0px 8px rgba(74, 178, 157, 0.6);
} */
.form-control-modified {
  border-radius: 12px;
  /* width: 30rem; */
}

select.selector {
  border: none;
  background-color: #84d9c9;
}

.selector:focus {
  border: none;
}

.selector:active {
  border: none;
}

/* todo: FOR DESKTOP SIZE */
@media (min-width: 1200px) {
  .form-control {
    border-radius: 12px;
    width: 30rem;
  }

  #singup-update-button {
    margin-left: 6rem;
  }

  .header-title {
    font-size: 50px;
    font-weight: 760;
    color: white;
  }

  #landing-card {
    margin-left: 2rem;
  }

  #landing-comparison-table {
    border-radius: 25px;
    /* font-weight: 600; */

    color: #595959;
    /* margin: 0; */
  }

  #original-comparison-table {
    border-radius: 25px;
    /* font-weight: 600; */

    color: #595959;
    /* margin: 0; */
  }

  #comparison-table-header {
    font-size: 25px;
    font-weight: 600;
    color: #595959;
  }
}
@media (min-width: 992px) {
  .form-control {
    border-radius: 12px;
    /* width: 30rem; */
  }

  .comparison-icons {
    width: 25px;
  }

  .comparison-icons-original {
    width: 35px;
  }

  #landing-comparison-table {
    border-radius: 25px;
    /* font-weight: 600; */
    font-size: 13px;
    max-width: 43vw;

    color: #595959;
    /* margin: 0; */
  }

  #original-comparison-table {
    border-radius: 25px;
    /* font-weight: 600; */
    font-size: 22px;
    /* max-width: 40vw; */

    color: #595959;
    /* margin: 0; */
  }

  #comparison-table-header {
    font-weight: 600;
    color: #595959;
  }
}

.header-title {
  font-size: 50px;
  font-weight: 760;
  color: white;
}

@media (max-width: 991.98px) {
  .form-control {
    margin-top: 0.5rem;
    border-radius: 12px;
  }

  .header-title {
    font-size: 50px;
    font-weight: 760;
    color: grey;
  }
  #intro-video {
    margin-top: 4rem;
  }
  #singup-update-button {
    margin-top: 20px;
    margin-left: 0;
  }

  #landing-comparison-table {
    border-radius: 25px;
    font-size: 13px;
    /* font-weight: 600; */

    color: #595959;
    /* margin: 0; */
  }

  #original-comparison-table {
    border-radius: 25px;
    font-size: 13px;
    /* font-weight: 600; */

    color: #595959;
    /* margin: 0; */
  }

  #comparison-table-header {
    font-weight: 600;
    color: #595959;
  }

  #landing-cyan-header-button {
    /* margin-top: 1rem; */
  }

  .comparison-icons {
    width: 24px;
  }

  .comparison-icons-original {
    width: 24px;
  }
}

#feature-landing-title {
  color: #2276c9;
}

@media (max-width: 775.98px) {
  #landing-comparison-table {
    border-radius: 25px;
    font-size: 10px;
    /* font-weight: 600; */
    color: black;
    /* margin: 0; */
  }

  #original-comparison-table {
    border-radius: 25px;
    font-size: 10px;
    /* font-weight: 600; */
    color: black;
    /* margin: 0; */
  }

  #comparison-table-header {
    font-weight: 600;
    color: #595959;
  }

  .comparison-icons {
    width: 20px;
  }

  .comparison-icons-original {
    width: 20px;
  }

  #intro-video {
    margin-top: 4rem;
    max-width: 720px;
  }
}

@media (max-width: 900px) {
  .hider {
    display: none;
    width: 0;
  }
}

@media (max-width: 950px) {
  .portfolio-text {
    font-size: 12px;
  }
}

@media (max-width: 470.98px) {
  #landing-comparison-table {
    border-radius: 25px;
    font-size: 5px;
    /* font-weight: 600; */
    color: black;
    /* margin: 0; */
  }

  #original-comparison-table {
    border-radius: 25px;
    font-size: 5px;
    /* font-weight: 600; */
    color: black;
    /* margin: 0; */
  }

  #comparison-table-header {
    font-weight: 600;
    color: #595959;
  }

  .comparison-icons {
    width: 15px;
  }

  .comparison-icons-original {
    width: 15px;
  }
}

/* textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  border-color: rgba(74, 178, 157, 0.75);
  box-shadow: 0px 1px 1px rgba(74, 178, 157, 0.75) inset,
    0px 0px 8px rgba(74, 178, 157, 0.6);
  outline: 0 none;
  background-color: white;
} */

input[type="email"]:focus,
.uneditable-input:focus {
  outline: 0 none;
  background-color: white;
}

.nav-item {
  transition: 0.3s;
}

.nav-item img:hover {
  opacity: 0.5;
  cursor: pointer;
  /* background: red; */
}

.btn-primary.custom-btn {
  border-radius: 10px;
  background-color: #2276c9;
  width: 100%;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-weight: 700;
  border: 0;
}

.btn-primary.custom-btn:focus {
  color: #fff;
  outline: none;
  box-shadow: none;
}

.bootstrap-off {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

.col-md-2 {
  display: inline;
}

.box {
  display: flex;
  justify-content: space-between;
}

#buyNow:hover {
  background-color: #2276c9;
  color: white;
  border-radius: 0px 0px 0px 25px;
}

#viewDetail {
  background-color: #4ab29d;
  color: white;
  border-radius: 0px 0px 25px 0px;
}

#viewDetail:hover {
  background-color: #478d7f;
  color: white;
  border-radius: 0px 0px 25px 0px;
}

.headerButtonBlue {
  background-color: #2276c9;
  color: white;
  border-radius: 11px;
  outline: none;
  overflow: auto;
  padding: 0.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  height: 120%;
  font-weight: bold;
  border-width: 0;
  /* width: 13rem; */
  height: auto;
}

.headerButtonBlue:hover,
.headerButtonBlue:focus {
  background-color: #4688ca;
}

.text-justify {
  text-align: justify;
}

.headerButtonCyan {
  background-color: #4ab29d;
  color: white;
  border-radius: 11px;
  outline: none;
  overflow: auto;
  padding: 0.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  height: auto;
  font-weight: bold;
  border-width: 0;
  /* width: 13rem; */
}

.headerButtonCyan:hover,
.headerButtonCyan:focus {
  background-color: #72beaf;
}

.headerIcons {
  width: 150px;
  transition: 0.3s;
  border-radius: 50%;
  border-width: 0;
  outline: none;
  overflow: auto;
}

.headerIcons:hover {
  cursor: pointer;
  width: 170px;
  -webkit-box-shadow: 1px 1px 15px 5px rgba(74, 178, 157, 1);
  box-shadow: 1px 1px 15px 5px rgba(74, 178, 157, 1);
  /* : "5px 5px 15px 5px rgba(0,0,0,0.25)", */
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.marketplace-long-card {
  /* background-color: white; */
  background: linear-gradient(
    0deg,
    rgba(240, 254, 251, 1) 0%,
    rgba(255, 255, 255, 1) 40%
  );
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  padding: 0;
  padding-top: 1.5%;
  padding-bottom: 1.5%;
}

a {
  color: white;
  text-decoration: none;
}

a:hover {
  color: #478d7f;
}

.sidebar {
  color: #2373c9;
  text-decoration: none;
}

.sidebar:hover {
  color: #4ab29d;
}

.blog-embed {
  color: white;
  background-color: #113e63;
  text-decoration: none;
}

.blog-embed:hover {
  /* color: #2373c9; */
  background-color: #2373c9;
}

.sidebar-blog-arrow {
  color: white;
  background-color: #4ab29d;
  text-decoration: none;
}

.sidesidebar-blog-arrowbar:hover {
  /* color: #2373c9; */
  background-color: #2373c9;
}

.bootstrap-off.market-card {
  width: 22rem;
  height: 31rem;
  border-radius: 25px;
  background-color: white;
  margin-bottom: 5rem;
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  cursor: pointer;
  -webkit-box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.25);
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.25);
  transition: 0.3s;
}

.bootstrap-off.market-card:hover {
  cursor: pointer;
  -webkit-box-shadow: 1px 1px 15px 5px rgba(74, 178, 157, 1);
  box-shadow: 1px 1px 15px 5px rgba(74, 178, 157, 1);
}

.line-section {
  height: 3x;
  width: 90%;
  /* margin-left: 45%;
  margin-right: 45%; */
  border-width: 0;
  color: gray;
  background-color: gray;
}

.vertical-center {
  /* margin: 0; */
  /* position: absolute; */
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
/* 
.row.display-flex {
  display: flex;
  flex-wrap: wrap;
}
.row.display-flex > [class*='col-'] {
  display: flex;
  flex-direction: column;
} */

.portfolioButtonBlue {
  background-color: #2276c9;
  color: white;
  border-radius: 5.7px;
  outline: none;
  overflow: auto;
  /* padding: 0.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem; */
  height: 100%;
  font-weight: bold;
  font-size: small;
  border-width: 0;
  width: 6vw;
}

.portfolioButtonBlue:hover,
.portfolioButtonBlue:focus {
  background-color: #4688ca;
}

.portfolioButtonCyan {
  background-color: #4ab29d;
  color: white;
  border-radius: 5.7px;
  outline: none;
  overflow: auto;
  /* padding: 0.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem; */
  height: 100%;
  font-weight: bold;
  font-size: small;
  border-width: 0;
  width: 6vw;
}

.portfolioButtonCyan:hover,
.portfolioButtonCyan:focus {
  background-color: #72beaf;
}

.portfolioButtonGrey {
  background-color: lightgrey;
  /* color: white; */
  border-radius: 5.7px;
  outline: none;
  overflow: auto;
  /* padding: 0.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem; */
  height: 100%;
  font-weight: bold;
  font-size: small;
  border-width: 0;
  /* width: 12vw; */
}

.padding-0 {
  padding-right: 10;
  padding-left: 10;
}
